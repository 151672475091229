<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            max-width="900"
            width="900"
            class="pa-5"
            flat
          >
            <h1 class="title mb-3">
              Page Not Found
            </h1>

            <p>
              We're sorry, but the page you're trying to access does not exist. We
              apologize for this inconvenience.
            </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Page Not Found',
  },
};
</script>
